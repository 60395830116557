$primary-color: #b28bce;
$primary-background-color: #b28bce;
$primary-background-color-75: rgba(178, 139, 206, 0.75);
$primary-background-color-50: rgba(178, 139, 206,0.5);
$primary-background-color-25: rgba(178, 139, 206,0.25);
$primary-background-color-light: #f6f3f9;
$primary-background-color-dark: #5c2e86;

$secondary-color: #5c2e86;
$secondary-background-color: #5c2e86;
$secondary-background-color-75: rgba(92, 46, 134, 0.75);
$secondary-background-color-50: rgba(92, 46, 134,0.5);
$secondary-background-color-25: rgba(92, 46, 134,0.35);
$secondary-background-color-light: #f6f3f9;


$red-color: rgba(196,32,0,1);
$red-background-color: rgba(196,32,0,1);

$text-color: #704092;
$title-color: darken($text-color, 5%);
$h1-color: #20215c;

// Cookie
$mainCookieBG: #b28bce;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;

    

@import url('https://fonts.googleapis.com/css?family=Lato');

body {
    color: #616161;
    header{
            .logo{
                    .logo-img.desktop{
                        height: 80px;
                    }
            }
            &.high{
                .logo-img.nvm {
                        top: 90px;
                        left: 0px;
                }
                .phonenumber{
                    left: 113px;
                    top: 105px;
                }
            }
            &.narrow {
                .logo-img.desktop{
                    top: 10px;
                    height:50px;
                    -webkit-transition: all 0.25s linear;
                    transition: all 0.25s linear;

                }
                .logo-img.nvm {
                    top: 10px;
                    left: 185px;
                }
                .phonenumber{
                    top: 25px;
                    left: 250px;
                }


        }
    }
    section.detail-maps .container .maps-wrapper:before,
    section.detail-photo-slider:before{
        background-color: $primary-background-color-25;
    }
    .btn.btn-secondary{
        background-color: #9066B1;
    }
    .btn:before{
        background-color: #623880;
    }
}
/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #5c2e86;
            }
            .footer-funda svg{
                fill: #5c2e86;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #5c2e86;
            }
            .footer-nrvg svg{
                fill: #5c2e86;
            }
        }
    }
}
